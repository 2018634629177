import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/menu.css'

function EventList() {
    const [eventos, setEventos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('http://agendautd.bravoutd.com:4000/eventos')
            .then(response => response.json())
            .then(data => setEventos(data))
            .catch(error => console.error('Error al obtener eventos:', error));
    }, []);

    const handleClick = (eventoId) => {
        navigate(`/eventinfo/${eventoId}`);
    };


    return (
        <div>
            <h1>Listado de Eventos</h1>
            <div className="event-list">
                {eventos.map(evento => (
                    <div key={evento.ID} className="event-item">
                        <h2>{evento.Nombre}</h2>
                        <p>{evento.Detalles}</p>
                        <p>Fecha de Inicio: {evento.FechaInicio}</p>
                        <button onClick={() => handleClick(evento.ID)}>Más Información</button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default EventList;
