import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import '../styles/addevent.css'

function AddConferencista() {

    const navigate = useNavigate();
    const [nombre, setNombre] = useState('');
    const [sexo, setSexo] = useState('');
    const [carrera, setCarrera] = useState('');
    const [empresa, setEmpresa] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = { nombre, sexo, carrera, empresa };
        fetch('http://agendautd.bravoutd.com:4000/addconferencistas', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Respuesta del servidor:', data);
            navigate('/addevent')
        })
        .catch(error => console.error('Error al enviar la solicitud:', error));
    };


    function Volver(){
        navigate("/addevent")
    }

    return (
        <div className="form-container">
            <h1>Añadir conferencistas</h1>
        <form>
            <label>
                Nombre:
                <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
            </label>
            <label>
                Sexo:
                <select value={sexo} onChange={(e) => setSexo(e.target.value)} required>
                    <option value="">Seleccionar sexo</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                    <option value="No especificado">Otro</option>
                </select>
            </label>
            <label>
                Carrera:
                <input type="text" value={carrera} onChange={(e) => setCarrera(e.target.value)} required />
            </label>
            <label>
                Empresa:
                <input type="text" value={empresa} onChange={(e) => setEmpresa(e.target.value)} required />
            </label>
            <button onClick={handleSubmit}>Enviar</button>
            <button className="back-button" onClick={Volver}>Regresar</button>
        </form>
    </div>
    );

}

export default AddConferencista;