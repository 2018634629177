import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../styles/updateuser.css'

function UsuarioUpdate() {


  const [usuario, setUsuario] = useState(null);
  const {userId} = useParams();
  const [Rol, setRol] = useState('')
  const [Nombres, setNombres] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [Telefono, setTelefono] = useState('');
  const [Correo, setCorreo] = useState('');
  const [IDCarrera, setIdCarrera] = useState('');
  const [carreras, setCarreras] = useState([]);
  const [mostrarInput, setMostrarInput] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`http://agendautd.bravoutd.com:4000/user/${userId}`)
        .then(response => response.json())
        .then(data => setUsuario(data))
        .catch(error => console.error('Error al obtener información del evento:', error));
}, [userId]);

  useEffect(() => {
    fetchCarreras();
  }, []);

  const fetchCarreras = () => {
    fetch('http://agendautd.bravoutd.com:4000/Carreras')
      .then(response => response.json())
      .then(data => setCarreras(data))
      .catch(error => console.error('Error al cargar las carreras:', error));
  };

  useEffect(() => {
    if (usuario) {
      setNombres(usuario.Nombres);
      setCorreo(usuario.Correo);
    }
  }, [usuario]);

  const Addmaster = (event) => {
    event.preventDefault();

    const Data = {
        Nombres: Nombres,
        Direccion: Direccion,
        Telefono: Telefono,
        Correo: Correo,
        IDCarrera: IDCarrera
      };

    fetch('http://agendautd.bravoutd.com:4000/MaestrosAdd', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Data)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Maestro creado:', data);
        navigate('/usuarios');
      })
      .catch(error => console.error('Error al crear el Maestro:', error));
  };

  function Volver(){
    navigate('/usuarios')
  }

  const handleRolChange = (e) => {
    const selectedRol = e.target.value;
    setRol(selectedRol);
    setMostrarInput(selectedRol === 'Maestro');
  };

  const CambiarRol = async () => {
    try {
      const response = await fetch('http://agendautd.bravoutd.com:4000/actualizar-rol', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idUsuario: usuario.ID, nuevoRol: Rol }),
      });
      const data = await response.json();
      console.log(data);
      alert('Rol actualizado con éxito');
    } catch (error) {
      console.error('Error al actualizar el rol:', error);
      alert('Error al actualizar el rol');
    }
    navigate('/usuarios')
  };

  return (
    <div className="updateuser-container">
      <h1 className="updateuser-title">Información del usuario</h1>
      {usuario ? (
        <div>
          <div className="updateuser-info">
            <p>{usuario.Nombres}</p>
            <p>{usuario.Correo}</p>
          </div>
          <label className="updateuser-label">
            Rol:
            <select className="updateuser-select" value={Rol} onChange={handleRolChange}>
              <option value="">Elija una opcion</option>
              <option value="Alumno">Alumno</option>
              <option value="Admin">Admin</option>
              <option value="Maestro">Maestro</option>
            </select>
          </label>
          {Rol !== 'Maestro' && (
            <button className="updateuser-button" onClick={CambiarRol}>Cambiar Rol</button>
          )}
          {Rol === 'Maestro' && (
            <div className="updateuser-maestro-inputs">
              <input
                className="updateuser-input"
                type="text"
                placeholder="Dirección"
                value={Direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
              />
              <input
                className="updateuser-input"
                type="text"
                placeholder="Teléfono"
                value={Telefono}
                onChange={(e) => setTelefono(e.target.value)}
                required
              />
              <label className="updateuser-label">
                <select className="updateuser-select" value={IDCarrera} onChange={(e) => setIdCarrera(e.target.value)} required>
                  <option value="">Seleccionar Carrera</option>
                  {carreras.map(carrera => (
                    <option key={carrera.ID} value={carrera.ID}>
                      {carrera.Nombre}
                    </option>
                  ))}
                </select>
              </label>
              <button className="updateuser-button" onClick={Addmaster}>Agregar Maestro</button>
            </div>
          )}
  
          <button className="updateuser-button updateuser-back-button" onClick={Volver}>Volver</button>
        </div>
      ) : (
        <p>Cargando información del usuario...</p>
      )}
    </div>
  );
  
  
}

export default UsuarioUpdate;
