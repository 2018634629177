import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Usuario.css";

function Maestros (){
  const [maestros, setMaestros] = useState([]);
  const [carreras, setCarreras] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('http://agendautd.bravoutd.com:4000/Carreras')
      .then(response => response.json())
      .then(data => setCarreras(data))
      .catch(error => console.error('Error al cargar los carreras:', error));
  }, []);
  
  useEffect(() => {
    fetch('http://agendautd.bravoutd.com:4000/maestros')
      .then(response => response.json())
      .then(data => setMaestros(data))
      .catch(error => console.error('Error al obtener los usuarios:', error));
  }, []);

  function handleDelete(MasterID) {
    fetch(`http://agendautd.bravoutd.com:4000/deleteMaster/${MasterID}`, {
      method: 'DELETE'
    })
    .then(response => {
      if (response.ok) {
        // Eliminación exitosa, actualiza la lista de usuarios
        setMaestros(maestros.filter(maestros => maestros.ID !== MasterID));
      } else {
        console.error('Error al eliminar al maestro');
      }
    })
    .catch(error => console.error('Error al eliminar el maestro:', error));
  }

    function Volver(){
      navigate('/home')
    }

    const getNombreCarreraMaestro = (idCarrera) => {
        const carrera = carreras.find(carrera => carrera.ID === idCarrera);
        return carrera ? carrera.Nombre : 'Carrera no encontrada';
    };
    
    
    return(
        <div>
      <div>
      <button className="volver" onClick={Volver}>Volver al menú</button>
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Direccion</th>
            <th>Telefono</th>
            <th>Correo</th>
            <th>Carrera</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {maestros.map(maestro => (
            <tr key={maestro.ID}>
              <td>{maestro.Nombres}</td>
              <td>{maestro.Direccion}</td>
              <td>{maestro.Telefono}</td>
              <td>{maestro.Correo}</td>
              <td>{getNombreCarreraMaestro(maestro.ID_Carrera)}</td>
              <td>
                <button className="delete" onClick={() => handleDelete(maestro.ID)}>Borrar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    )



}

export default Maestros