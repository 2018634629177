import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Usuario.css";

function CarrerasList() {
  const [carreras, setCarreras] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('http://agendautd.bravoutd.com:4000/Carreras')
      .then(response => response.json())
      .then(data => setCarreras(data))
      .catch(error => console.error('Error al cargar las carreras:', error));
  }, []);

  useEffect(() => {
    fetch('http://agendautd.bravoutd.com:4000/Grupos')
      .then(response => response.json())
      .then(data => setGrupos(data))
      .catch(error => console.error('Error al cargar los grupos:', error));
  }, []);


  function DeleteGrupo(GrupoID) {
    fetch(`http://agendautd.bravoutd.com:4000/deletegrupo/${GrupoID}`, {
      method: 'DELETE'
    })
      .then(response => {
        if (response.ok) {
          setGrupos(grupos.filter(grupo => grupo.ID !== GrupoID));
        } else {
          console.error('Error al eliminar el grupo');
        }
      })
      .catch(error => console.error('Error al eliminar el grupo:', error));
  }
  function Volver() {
    navigate('/home');
  }

  function agregarGrupos() {
    navigate('/GruposAdd');
  }

  // Función para obtener el nombre de la carrera por ID
  function getCarreraNombre(carreraID) {
    const carrera = carreras.find(c => c.ID === carreraID);
    return carrera ? carrera.Nombre : 'Carrera no encontrada';
  }

  return (
    <div>
      <div>
        <button className="volver" onClick={Volver}>Volver al menú</button>
        <button className="add" onClick={agregarGrupos}>Agregar grupos</button>
      </div>
        <h1>Lista de Grupos</h1>
      <table className="user-table">
        <thead>
          <tr>
            <th>Carrera</th>
            <th>Grupo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {grupos.sort((a, b) => a.Carrera_ID - b.Carrera_ID).map(grupo => (
            <tr key={grupo.ID}>
              <td>{getCarreraNombre(grupo.Carrera_ID)}</td>
              <td>{grupo.Nombre}</td>
              <td>
                <button className="delete-small" onClick={() => DeleteGrupo(grupo.ID)}>Borrar Grupo</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CarrerasList;
