import { useSession,useSupabaseClient } from '@supabase/auth-helpers-react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import '../styles/Login.css'   
import Home from './Home';

function Login() {
    const session = useSession();
    const supabase = useSupabaseClient();

    const handleAuthChange = async (event, session) => {
        if (event === "SIGNED_IN") {
            const { user } = session;
            const { email, user_metadata: { full_name, avatar_url } } = user;
    
            await fetch('http://agendautd.bravoutd.com:4000/AddUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, fullName: full_name, photoURL: avatar_url }),
            });
        }
    };

    supabase.auth.onAuthStateChange(handleAuthChange);

    return (
        <div>
            <div>
                {session ?
                    <>
                      <Home/>
                    </>
                    :
                    <>
                        <div className='loginboddy'>
                            <Auth
                                supabaseClient={supabase}
                                appearance={{ theme: ThemeSupa }}
                                theme="dark"
                                providers={["google"]}
                                providerScopes={{
                                    google:'https://www.googleapis.com/auth/calendar'
                                }}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Login
