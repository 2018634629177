import React, { useState, useEffect } from 'react';
import { useSession } from '@supabase/auth-helpers-react';
import { useParams,useNavigate } from 'react-router-dom';
import '../styles/eventoinfo.css'


function EventInfo() {

    const session=useSession();

    const [evento, setEvento] = useState(null);
    const { eventoId } = useParams();
    const navigate = useNavigate();
    const [conferencistas, setConferencistas] = useState([]);
    const [carreras, setCarreras]=useState([]);
    const [grupos, setGrupos]=useState([]);
    //guardar valores
    

    useEffect(() => {
        fetch(`http://agendautd.bravoutd.com:4000/evento/${eventoId}`)
            .then(response => response.json())
            .then(data => setEvento(data))
            .catch(error => console.error('Error al obtener información del evento:', error));
    }, [eventoId]);

    useEffect(() => {
        fetch('http://agendautd.bravoutd.com:4000/conferencistas')
          .then(response => response.json())
          .then(data => setConferencistas(data))
          .catch(error => console.error('Error al cargar los conferencistas:', error));
      }, []);
      const getNombreConferencista = (idConferencista) => {
        const conferencista = conferencistas.find(conferencista => conferencista.ID === idConferencista);
        return conferencista ? conferencista.Nombre : 'Conferencista no encontrado';
    };

    useEffect(() => {
        fetch('http://agendautd.bravoutd.com:4000/Carreras')
          .then(response => response.json())
          .then(data => setCarreras(data))
          .catch(error => console.error('Error al cargar las carreras:', error));
      }, []);
      const getCarrera = (idcarrera) => {
        const carrera = carreras.find(carrera => carrera.ID === idcarrera);
        return carrera ? carrera.Nombre: 'Carrera no encontrada'
      }

      useEffect(() => {
        fetch('http://agendautd.bravoutd.com:4000/Grupos')
          .then(response => response.json())
          .then(data => setGrupos(data))
          .catch(error => console.error('Error al cargar los grupos:', error));
      }, []);
      const getGrupos = (idgrupos) => {
        const grupo = grupos.find(grupo => grupo.ID === idgrupos);
        return grupo ? grupo.Nombre:'Grupo no encontrado' 
      }


    function Volver(){
        navigate(`/home`);
    }

    async function AddEvent(){
        console.log("creando el evento en el calendario de google ")
        const event = {
            'summary': evento.Nombre,
            'description': evento.Detalles,
            'start':{
                'dateTime': new Date (evento.FechaInicio).toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            'end':{
                'dateTime': new Date (evento.FechaCierre).toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
        }
        await fetch("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
            method: "POST",
            headers: {
                'Authorization':'Bearer ' + session.provider_token
            },
            body: JSON.stringify(event)
        }).then((data) => {
            return data.json();
        }).then((data) => {
            console.log(data);
            alert('Evento agregado, checa tu calendario')
        })
    }

    return (
        <div className="event-info-container">
            <h1>Información del Evento</h1>
            {evento ? (
                <div>
                    <h2>{evento.Nombre}</h2>
                    <p>{evento.Detalles}</p>
                    <p>Fecha de Inicio: {evento.FechaInicio}</p>
                    <p>Fecha de Cierre: {evento.FechaCierre}</p>
                    <p>Conferencista: {getNombreConferencista(evento.ID_Conferencista)}</p>
                    <p>lugar de realizacion: {evento.Lugar}</p>
                    <p>Grupo enfocado: {getGrupos(evento.IDGrupo)}</p>
                    <p>Carrera Enfocada: {getCarrera(evento.IDCarrera)}</p>
                    
                    <button onClick={() => AddEvent()}>Agendar Evento</button>

                    <button onClick={Volver} className="back-button">Volver al menu</button>
                </div>
            ) : (
                <p>Cargando...</p>
            )}
        </div>
    );

}

export default EventInfo;
